// main.js
import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus, { ClickOutside } from 'element-plus';
import 'element-plus/dist/index.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const app = createApp(App);
app.use(ElementPlus);

// Register the v-click-outside directive globally
app.directive('click-outside', ClickOutside);

app.mount('#app');
