<template>
  <div id="map-container">
    <div id="map"></div>


    <el-button
      class="route-tracking-button"
      @click="toggleRouteTracking"
    >
      <el-icon class="RouteIcon">
        <!-- Use an appropriate route icon here -->
        <el-icon class="Compass">
          <compass />
        </el-icon>
      </el-icon>
    </el-button>
    
    <!-- Center Map Button -->
    
      <el-button
        class="center-map-button"
        @click="centerMapOnIsrael"
      >
        <el-icon class="Compass">
          <compass />
        </el-icon>
      </el-button>
   
    
    <!-- Most Active Location Display with Tooltip -->
    <el-tooltip
      content="Shows the most active location in the past hour"
      placement="right"
      effect="dark"
      popper-class="custom-tooltip-padding"
    >
      <el-row :gutter="16" class="most-active-location">
        <el-col :span="8">
          <div class="statistic-footer">
            <div class="footer-item">
              <span class="location-display" @click="focusOnMostActiveLocation">
                <el-icon class="location-icon">
                  <Location />
                </el-icon>
                {{ mostActiveLocation }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-tooltip>



    
    <!-- Floating Feed Button with Notification Badge -->
    <el-affix position="bottom" :offset="100" id="news-button">
      <el-button
        :icon="Tickets"
        type="primary"
        :style="newsButtonStyle"
        @click="toggleFeed"
      > <span v-if="newEventCount > 0" class="badge">{{ newEventCount }}</span>
      </el-button>
    </el-affix>

    <!-- Real-Time Toggle Button -->
    <el-affix position="bottom" :offset="20" id="time-toggle">
      <el-button
        :type="buttonType"
        @click="toggleRealTime"
        :loading="loading"
        :disabled="antiSpam"
        :class="{ 'animate-blink': realTimeStream }"
      >
        <span class="status-circle" :class="{ 'online': realTimeStream, 'offline': !realTimeStream }"></span>
        {{ buttonText }}
      </el-button>
    </el-affix>

    <!-- BAZ Logo with Popup -->
    <el-tooltip content="About this App" placement="top" effect="dark">
      <div id="logo" @click="showAbout = true">BAZ</div>
    </el-tooltip>

    <!-- Traffic Meter Display with Tooltip -->
    <el-tooltip content="This meter tracks the current event activity levels" placement="right" effect="dark">
      <el-row :gutter="16" class="traffic-meter">
        <el-col :span="8">
          <div class="statistic-footer">
            <div class="footer-item">
              <span :class="trafficColorClass" class="traffic-percentage" @click="calculateTraffic">
                <el-icon class="traffic-icon">
                  <template v-if="trafficPercentage > 0">
                    <CaretTop />
                  </template>
                  <template v-else>
                    <CaretBottom />
                  </template>
                </el-icon>
                {{ trafficPercentage }}%
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-tooltip>

    <!-- Bottom Sheet Feed -->
    <transition name="slide-up">
      <div
        v-if="isFeedVisible"
        class="bottom-sheet"
        :style="{ transform: `translateY(${translateY}px)` }"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <div class="feed-header">
          <el-tabs v-model="activeLocation">
            <el-tab-pane label="All" name="all"></el-tab-pane>
            <el-tab-pane
              v-for="location in uniqueLocations"
              :key="location"
              :label="location"
              :name="location"
            ></el-tab-pane>
          </el-tabs>
          <el-button @click="toggleFeed">Close</el-button>
        </div>
        <el-scrollbar height="300px" class="feed-container">
          <div
            v-for="event in filteredEvents"
            :key="event.id"
            :class="['feed-item', { highlighted: highlightedEvent === event.id }]"
          >
            <el-card shadow="never" class="event-card">
              <div class="event-header">
                <span class="event-time">{{ formatTime(event.timestamp) }}</span>
                <el-button type="text" size="small" @click="centerMapOnEvent(event)">
                  View on Map
                </el-button>
                <el-icon @click="zoomToLocation(event.lat, event.lng)">
                  <Location />
                </el-icon>
              </div>
              <div class="event-description">{{ event.translated_message }}</div>
              <!-- Display hashtags -->
              <div class="flex gap-2">
                <el-tag v-for="tag in event.hashtags" :key="tag.name" closable type="info">
                  {{ tag.name }}
                </el-tag>
              </div>
            </el-card>
          </div>
        </el-scrollbar>
      </div>
    </transition>

    <!-- About Popup Dialog -->
    <el-dialog
      v-model="showAbout"
      title="About"
      :close-on-click-modal="false"
      width="80%"
    >
      <span>
        This app provides real-time updates on events happening around you, displaying them on an interactive map.
        Events are color-coded based on their recency, with red markers indicating the most recent events and blue markers representing older ones.
        If you find this app useful, consider supporting me by making a donation. Your support helps me keep the service running and improving.
      </span>
      <br /><br />
      <el-button type="primary" @click="openDonationLink">Donate</el-button>
      <el-button @click="showAbout = false">Close</el-button>
    </el-dialog>

    <!-- Legend -->
    <div id="legend">
      <div class="legend-item">
        <div class="legend-color" style="background-color: rgb(0, 0, 255);"></div>
        <span>Least Recent</span>
      </div>
      <div class="legend-item">
        <div class="legend-color" style="background-color: rgb(255, 0, 0);"></div>
        <span>Most Recent</span>
      </div>
      <div class="legend-item">
        <div class="legend-color heat-dot" style="background-color: rgba(0, 50, 255, 1.5);"></div>
        <span>Low Density</span>
      </div>
      <div class="legend-item">
        <div class="legend-color heat-dot" style="background-color: rgba(255, 83, 73, 1.5);"></div>
        <span>High Density</span>
      </div>
    </div>

    <!-- Loading Indicator -->
    <div v-if="loading" class="loading-overlay">
      <div class="loader"></div>
      <div class="loading-text">Loading events...</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { ElNotification, ElMessage } from 'element-plus';
import L from 'leaflet';
import 'leaflet.heat';
import { Compass, Tickets, Location, CaretTop, CaretBottom } from '@element-plus/icons-vue';

const map = ref(null);
const heatmapLayer = ref(null);
const heatmapData = ref([]);
const events = ref([]);
const markers = ref([]);
const highlightedMarker = ref(null);
const buttonText = ref('Real-Time');
const buttonType = ref('success');
const realTimeStream = ref(true);
const showAbout = ref(false);
const loading = ref(false);
const antiSpam = ref(false);
const newEventCount = ref(0);
const isFeedVisible = ref(false);
const activeLocation = ref('all');
const highlightedEvent = ref(null);
const searchQuery = ref("");  
let eventSource = null;
let trafficInterval = null;

const translateY = ref(0);
const startY = ref(0);

// Traffic-related variables
const trafficPercentage = ref(0);
const previousHalfHourCount = ref(0);
const currentHalfHourCount = ref(0);

// Compute traffic percentage and direction
const trafficColorClass = computed(() => {
  return trafficPercentage.value > 0 ? 'traffic-rising' : 'traffic-falling';
});

const calculateTraffic = () => {
  const now = Date.now();
  const thirtyMinutesAgo = now - 10 * 60 * 1000;

  const eventsLast30Minutes = events.value.filter(event => event.timestamp * 1000 >= thirtyMinutesAgo);
  const eventsPrevious30Minutes = events.value.filter(event => event.timestamp * 1000 < thirtyMinutesAgo && event.timestamp * 1000 >= thirtyMinutesAgo - 30 * 60 * 1000);

  currentHalfHourCount.value = eventsLast30Minutes.length;
  previousHalfHourCount.value = eventsPrevious30Minutes.length;

  if (previousHalfHourCount.value > 0) {
    trafficPercentage.value = Math.round(((currentHalfHourCount.value - previousHalfHourCount.value) / previousHalfHourCount.value) * 100);
  } else {
    trafficPercentage.value = currentHalfHourCount.value > 0 ? 100 : 0;
  }
};

// Function to center the map on Israel with zoom level 13
const centerMapOnIsrael = () => {
  if (map.value) {
    map.value.setView([31.5, 35], 7); // Israel coordinates and zoom level
  }
};

// Watch events and recalculate traffic whenever events are updated
watch(events, calculateTraffic, { immediate: true });

onMounted(() => {
  initMap();

  map.value.createPane('oldMarkers');
  map.value.createPane('newMarkers');

  map.value.getPane('oldMarkers').style.zIndex = 400; // Lower z-index for older markers
  map.value.getPane('newMarkers').style.zIndex = 500; // Higher z-index for new markers

  loadLast20Events();
  if (realTimeStream.value) {
    initSSE();
  }

  if (!localStorage.getItem('tourCompleted')) {
    showAbout.value = true;
  }

  // Set up interval to update traffic meter every minute
  trafficInterval = setInterval(calculateTraffic, 60000); // 60000 ms = 1 minute
});

onUnmounted(() => {
  if (trafficInterval) {
    clearInterval(trafficInterval);
  }
});

watch(realTimeStream, (newVal) => {
  if (newVal) {
    initSSE();
    ElMessage.info('Real-Time Updates Enabled');
    buttonType.value = 'success';  // Set button color to green when connected
    buttonText.value = 'Real-Time';
  } else {
    closeSSE();
    buttonText.value = 'Disconnected';
    buttonType.value = 'danger';
    ElMessage.info('Real-Time Updates Disabled');
  }
});

const mostActiveLocation = ref('');

const calculateMostActiveLocation = () => {
  const now = Date.now();
  const oneHourAgo = now - 60 * 60 * 1000; // 1 hour in milliseconds
  const locationFrequency = {};

  events.value.forEach(event => {
    if (event.timestamp * 1000 >= oneHourAgo) { // Consider only events from the past hour
      const location = event.location;
      if (location) {
        locationFrequency[location] = (locationFrequency[location] || 0) + 1;
      }
    }
  });

  mostActiveLocation.value = Object.keys(locationFrequency).reduce((a, b) =>
    locationFrequency[a] > locationFrequency[b] ? a : b, ''
  );
};

watch(events, calculateMostActiveLocation, { immediate: true });

const focusOnMostActiveLocation = () => {
  const locationEvent = events.value.find(event => event.location === mostActiveLocation.value);
  if (locationEvent) {
    zoomToLocation(locationEvent.lat, locationEvent.lng);
  }
};

const newsButtonStyle = ref({
  fontSize: '24px',
  padding: '15px 10px',
  borderRadius: '5px',
  backgroundColor: '#409EFF',
  color: '#fff',
  transition: 'all 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const toggleFeed = () => {
  isFeedVisible.value = !isFeedVisible.value;
  if (isFeedVisible.value) {
    newEventCount.value = 0;
  }
};

const uniqueLocations = computed(() => {
  const locations = events.value.map(event => event.location).filter(Boolean);
  return [...new Set(locations)];
});

const filteredEvents = computed(() => {
  let eventsToFilter = events.value;

  if (activeLocation.value !== 'all') {
    eventsToFilter = eventsToFilter.filter(event => event.location === activeLocation.value);
  }

  if (searchQuery.value) {
    eventsToFilter = eventsToFilter.filter(event =>
      event.translated_message.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  }

  return eventsToFilter;
});

// Generate hashtags based on event keywords
const generateHashtags = (keywords) => {
  return keywords.map(keyword => ({
    name: `#${keyword}`,
    type: 'info'
  }));
};

const initMap = () => {
  map.value = L.map('map').setView([31.5, 34.5], 6);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
  }).addTo(map.value);

  heatmapLayer.value = L.heatLayer([], {
    radius: 60,
    blur: 20,
    maxZoom: 15,
  }).addTo(map.value);
};

const loadLast20Events = async () => {
  loading.value = true;
  try {
    const response = await fetch('/api/events?limit=20');
    if (!response.ok) {
      throw new Error('Failed to fetch events');
    }
    const data = await response.json();

    // Generate hashtags for each event
    data.forEach(event => {
      event.hashtags = generateHashtags(event.keywords || []);
    });

    events.value = data.sort((a, b) => b.timestamp - a.timestamp);

    updateMap();
    focusOnNewestEvent();
  } catch (error) {
    console.error('Error loading events:', error);
    ElMessage.error('Error loading events');
  } finally {
    loading.value = false;
  }
};

const initSSE = () => {
  if (eventSource) return;

  eventSource = new EventSource('/api/events/stream');

  eventSource.onmessage = (event) => {
    const newEvents = JSON.parse(event.data);

    if (Array.isArray(newEvents)) {
      newEvents.forEach(eventData => processEvent(eventData));
    } else {
      processEvent(newEvents);
    }
  };

  eventSource.onerror = () => {
    // Handle SSE connection error
    realTimeStream.value = false;
    buttonText.value = 'Disconnected';
    buttonType.value = 'danger';
    ElMessage.error('Failed to connect to real-time updates');
  }
};

const processEvent = (eventData) => {
  const existingEvent = events.value.find(event => event.id === eventData.id);
  if (!existingEvent) {
    eventData.hashtags = generateHashtags(eventData.keywords || []);
    events.value.unshift(eventData);
    newEventCount.value++;
    addHeatmapData(eventData);
    addMarker(eventData);
    highlightNewEvent(eventData);

    // Add a notification when a new event is received
    ElNotification({
      title: 'New Event Received',
      message: eventData.translated_message,
      type: 'info',
      duration: 4000,
      onClick: () => {
        zoomToLocation(eventData.lat, eventData.lng);
      }
    });
  }
};

const highlightNewEvent = (eventData) => {
  highlightedEvent.value = eventData.id;
  setTimeout(() => {
    highlightedEvent.value = null;
  }, 30000);
};

const closeSSE = () => {
  if (eventSource) {
    eventSource.close();
    eventSource = null;
  }
};

const toggleRealTime = () => {
  if (antiSpam.value) return;

  antiSpam.value = true;
  setTimeout(async () => {
    antiSpam.value = false;

    if (realTimeStream.value) {
      realTimeStream.value = false;
    } else {
      await fetchRecentData();
      realTimeStream.value = true;
    }
  }, 2000);
};

const fetchRecentData = async () => {
  loading.value = true;
  try {
    const response = await fetch('/api/events?limit=20');
    if (!response.ok) {
      throw new Error('Failed to fetch recent data');
    }
    const data = await response.json();

    // Generate hashtags for each event
    data.forEach(event => {
      event.hashtags = generateHashtags(event.keywords || []);
    });

    events.value = data.sort((a, b) => b.timestamp - a.timestamp);
    updateMap();
  } catch (error) {
    console.error('Error fetching recent data:', error);
    ElMessage.error('Error fetching recent data');
  } finally {
    loading.value = false;
  }
};

const reconnectStream = () => {
  realTimeStream.value = true;
  initSSE();
  buttonType.value = 'success';  // Change button color to green when reconnecting
  buttonText.value = 'Real-Time';
  newEventCount.value = 0;
};

const onTouchStart = (event) => {
  startY.value = event.touches[0].clientY;
};

const onTouchMove = (event) => {
  const touchY = event.touches[0].clientY;
  const deltaY = touchY - startY.value;

  if (deltaY > 0) {
    translateY.value = deltaY;
  }
};

const onTouchEnd = () => {
  if (translateY.value > 100) {
    isFeedVisible.value = false;
  }
  translateY.value = 0;
};

const updateMap = () => {
  if (map.value) {
    map.value.eachLayer((layer) => {
      if (layer instanceof L.CircleMarker) {
        map.value.removeLayer(layer);
      }
    });

    events.value.forEach((event) => {
      addHeatmapData(event);
      addMarker(event);
    });

    focusOnNewestEvent();
  }
};

const addHeatmapData = (eventData) => {
  const { lat, lng } = eventData;

  let intensity = 1;

  const existingData = heatmapData.value.find(item => item[0] === lat && item[1] === lng);

  if (existingData) {
    existingData[2] += intensity;
  } else {
    heatmapData.value.push([lat, lng, intensity]);
  }

  if (heatmapLayer.value) {
    heatmapLayer.value.setLatLngs(heatmapData.value);
  }
};

let currentZIndex = 500;

const addMarker = (eventData) => {
  const { lat, lng, translated_message, timestamp } = eventData;

  if (lat === undefined || lng === undefined || isNaN(lat) || isNaN(lng)) {
    console.error('Invalid LatLng:', lat, lng);
    return;
  }

  const offsetLat = lat + (Math.random() - 0.5) * 0.015;
  const offsetLng = lng + (Math.random() - 0.5) * 0.015;

  const markerColor = getColorForEvent(new Date(timestamp * 1000).getTime());

  // Alternate between old and new markers based on z-index management
  const paneName = `pane-${currentZIndex}`;
  map.value.createPane(paneName);
  map.value.getPane(paneName).style.zIndex = currentZIndex;

  const marker = L.circleMarker([offsetLat, offsetLng], {
    pane: paneName,  // Assign the marker to the custom pane
    radius: 6,
    fillColor: markerColor,
    color: markerColor,
    weight: 0.5,
    opacity: 1,
    fillOpacity: 1
  }).addTo(map.value);

  marker.bindPopup(
    `<b>${translated_message}</b><br>${new Date(timestamp * 1000).toLocaleString()}`
  );

  markers.value.push(marker);

  // Increase z-index for the next marker
  currentZIndex += 1;
};

const getColorForEvent = (eventTime) => {
  const now = Date.now();
  const ageInHours = (now - eventTime) / (60 * 60 * 1000);
  const minAge = 0;
  const maxAge = 1;
  const ageRatio = Math.min(Math.max((ageInHours - minAge) / (maxAge - minAge), 0), 1);

  const startColor = { r: 255, g: 0, b: 0 };
  const endColor = { r: 0, g: 0, b: 255 };

  const color = {
    r: Math.round(startColor.r + ageRatio * (endColor.r - startColor.r)),
    g: Math.round(startColor.g + ageRatio * (endColor.g - startColor.g)),
    b: Math.round(startColor.b + ageRatio * (endColor.b - startColor.b)),
  };

  return `rgb(${color.r},${color.g},${color.b})`;
};

const focusOnNewestEvent = () => {
  if (events.value.length > 0) {
    const newestEvent = events.value[0];
    const marker = markers.value.find(m => {
      const latLng = m.getLatLng();
      return latLng.lat === newestEvent.lat && latLng.lng === newestEvent.lng;
    });

    if (marker) {
      map.value.setView(marker.getLatLng(), 7);
      marker.openPopup();
    } else {
      console.error('Marker not found for the newest event');
    }
  }
};

const completeTour = () => {
  localStorage.setItem('tourCompleted', 'true');
};

const openDonationLink = () => {
  window.open('https://buymeacoffee.com/david123', '_blank');
};

const formatTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString();
};

const centerMapOnEvent = (eventData) => {
  const { lat, lng } = eventData;
  const marker = markers.value.find(m => m.relatedEventId === eventData.id);

  if (marker) {
    if (highlightedMarker.value) {
      highlightedMarker.value.setStyle({ radius: 6, fillColor: getColorForEvent(highlightedMarker.value.eventTime) });
    }

    marker.setStyle({ radius: 12, fillColor: 'yellow' });

    highlightedMarker.value = marker;

    map.value.setView(marker.getLatLng(), 7);
    marker.openPopup();

    setTimeout(() => {
      marker.setStyle({ radius: 6, fillColor: getColorForEvent(eventData.timestamp * 1000) });
    }, 2000);
  } else {
    console.error('Marker not found for the selected event');
  }
};

const zoomToLocation = (lat, lng) => {
  if (map.value) {
    map.value.setView([lat, lng], 13);
  }
};

watch(events, (newEvents) => {
  if (newEvents.length > 0) {
    const newEvent = newEvents[0];
    highlightedEvent.value = newEvent.id;

    setTimeout(() => {
      highlightedEvent.value = null;
    }, 30000);
  }
});
</script>


<style scoped>

  .center-map-button {
    position: absolute;
    bottom: 180px; /* Adjust this value as needed to place it above the legend */
    right: 25px;
    z-index: 1100;
    background-color: #409EFF;
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .center-map-button:hover {
    background-color: #67c23a; /* Success color on hover */
    transform: scale(1.1);
  }

  .location-icon {
    margin-right: 4px;
    margin-bottom: 1px;
    font-size: 14px;
  }

  
  .custom-tooltip-padding {
    padding: 5px; /* Adjust the padding value as needed */
  }
  
  /* General Styles */
  .most-active-location {
    position: absolute;
    bottom: 110px; /* Position above the traffic meter */
    left: 30px;
    z-index: 1100;
  }

  .location-display {
    background-color: #409EFF; /* Set the background to blue */
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .location-icon {
    margin-right: 4px;
    margin-bottom: 1px;
    font-size: 14px;
  }

  .location-display:hover {
    background-color: #67c23a; /* Success color on hover */
    transform: scale(1.1);
  }


  .traffic-meter {
    position: absolute;
    bottom: 70px; /* Position above the real-time toggle button */
    left: 30px;
    z-index: 1100;
  }

  .traffic-percentage {
    background-color: var(--el-bg-color-overlay);
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .traffic-percentage.traffic-rising {
    background-color: #67c23a; /* Success color */
  }

  .traffic-percentage.traffic-falling {
    background-color: #f56c6c; /* Error color */
  }

  .traffic-icon {
    margin-right: 4px;
    margin-bottom: 1px;
    font-size: 14px;
  }

  .feed-container {
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
  }

  .feed-item {
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }

  .event-card {
    border-radius: 10px;
    padding: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }

  .event-card:hover {
    background-color: #f0f0f0;
    transform: scale(1.02);
  }

  .event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .event-time {
    font-size: 12px;
    color: #888;
  }

  .event-description {
    font-size: 14px;
    color: #333;
  }

  .highlighted {
    background-color: yellow;
  }

  .news-feed-popover {
    max-height: 600px;
    overflow-y: auto;
  }

  #map-container {
    height: 100dvh;
    width: 100vw;
    position: relative;
  }

  #map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  /* Bottom sheet for event feed */
  .bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 16px 16px 0 0;
    z-index: 2000;
    transition: transform 0.3s ease;
  }

  .feed-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #f5f5f5;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  /* Slide-up transition for the bottom sheet */
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-up-enter-from,
  .slide-up-leave-to {
    transform: translateY(100%);
  }

  /* Full-page overlay */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Light overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000; /* Ensure it's above other content */
  }

  /* Loader container */
  .loader {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #409EFF; /* Element Plus primary color */
    animation: spin 1s ease-in-out infinite;
  }

  /* Spin animation */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  /* Text below loader */
  .loading-text {
    margin-top: 10px;
    font-size: 18px;
    color: #409EFF; /* Element Plus primary color */
    font-weight: bold;
    text-align: center;
  }

  /* Optional: Dark mode styles */
  @media (prefers-color-scheme: dark) {
    .loading-overlay {
      background: rgba(0, 0, 0, 0.8);
    }
    .loading-text {
      color: #fff;
    }
  }

  #news-button {
    position: absolute;
    bottom: 620px;
    left: 25px;
    z-index: 1000;
  }

  #time-toggle {
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 1000;
  }

  #logo {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    font-weight: bold;
    color: black;
    z-index: 1000;
    cursor: pointer;
  }

  .el-button {
    margin: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .el-button.success {
    background-color: green;
    border-color: green;
  }

  .el-button.info {
    background-color: blue;
    border-color: blue;
  }

  .el-button.warning {
    background-color: orange;
    border-color: orange;
  }

  .el-button.danger {
    background-color: red;
    border-color: red;
  }

  .el-button:hover {
    transform: scale(1.05);
  }

  .status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: relative;
    left: -5px; /* Adjust as necessary */
    margin-right: 5px;
  }

  .status-circle.online {
    background: green;
    animation: blink-animation 2s infinite;
  }

  .status-circle.offline {
    background: red;
  }

  @keyframes blink-animation {
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  .badge {
    background-color: red;
    color: white;
    padding: 2px 5px;
    border-radius: 50%;
    margin-left: 8px;
  }

  #legend {
    position: absolute;
    bottom: 35px;
    right: 30px;
    background: white;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    font-size: 16px;
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .legend-color {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .heat-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    filter: blur(2px); /* Apply blur effect to simulate heatmap */
  }

  /* Mobile-specific styles */
  @media (max-width: 600px) {
    .traffic-meter {
      bottom: 60px;
      left: 15px;
      font-size: 12px; /* Smaller font size for mobile */
      z-index: 1100;
    }

    .traffic-percentage {
      padding: 2px 6px; /* Reduce padding on mobile */
      font-size: 12px; /* Smaller font size for better readability on small screens */
      border-radius: 8px; /* Adjust border-radius to fit the smaller size */
    }

    .traffic-icon {
      font-size: 12px; /* Smaller icon size for mobile */
      margin-right: 2px; /* Adjust margin to fit mobile layout */
    }

@media (max-width: 600px) {
  .custom-tooltip-location {
    left: 50px; /* Adjust the padding value as needed */
  
  }
}
    
    @media (max-width: 600px) {
    .most-active-location {
      bottom: 90px; /* Adjust position above the traffic meter on mobile */
      left: 15px;   /* Move closer to the edge on mobile */
      font-size: 12px; /* Smaller font size for mobile */
    }

    .location-display {
      padding: 2px 6px; /* Reduce padding for smaller screens */
      font-size: 12px; /* Smaller font size for better readability on small screens */
      border-radius: 8px; /* Adjust border-radius to fit the smaller size */
      background-color: #409EFF; /* Ensure blue background is consistent on mobile */
    }

    .location-icon {
      font-size: 14px; /* Smaller icon size for mobile */
      margin-right: 2px; /* Adjust margin to fit mobile layout */
    }
    }

    #news-button {
      left: 10px;
      bottom: 300px; /* Position above the Real-Time button */
      z-index: 1100;
    }

    #time-toggle {
      left: 10px;
      bottom: 10px; /* Maintain space between the two buttons */
      z-index: 1100;
    }


    #legend {
      right: 10px;
      bottom: 10px;
      font-size: 10px;
      padding: 5px;
    }

    .legend-color {
      width: 10px;
      height: 10px;
    }

    .feed-container {
      max-height: 300px; /* Reduce the max-height for smaller screens */
      width: 90%; /* Make the container fit better on smaller screens */
      padding: 5px; /* Adjust padding for smaller screens */
      margin: 0 auto; /* Center the feed container */
    }

    .event-card {
      padding: 5px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Adjust font size for readability on smaller screens */
    }

    .event-time,
    .event-description {
      font-size: 12px; /* Smaller font size for mobile */
    }

    .el-scrollbar__wrap {
      max-height: 300px; /* Ensure scrollbar height matches container */
    }
  }

  /* Adjust the legend for very small screens */
  @media (max-width: 400px) {
    #legend {
      right: 10px;
      bottom: 10px;
      font-size: 10px;
      padding: 5px;
    }

    .legend-color {
      width: 10px;
      height: 10px;
    }
  }

  @media (max-width: 600px) {
    .center-map-button {
      bottom: 140px; /* Adjust to place it appropriately on smaller screens */
      right: 15px; /* Move closer to the edge of the screen */
      padding: 3px 6px; /* Reduce padding for smaller screens */
      font-size: 12px; /* Adjust font size for mobile */
      border-radius: 30px; /* Slightly smaller border-radius */
    }

    .location-icon {
      font-size: 14px; /* Smaller icon size for mobile */
      margin-right: 2px; /* Adjust margin to fit mobile layout */
    }
  }
</style>


<style>
  body {
    margin: 0px;
  }
</style>
